
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import TargetDrawer from '@/layout/header/partials/trainning_institute/InstituteTargetDrawer.vue';
import ChangeSummaryDrawer from '@/layout/header/partials/trainning_institute/TargetChangeSummeryDrawer.vue';
//import TargetModal from "@/components/modals/forms/institute/InstituteTarget.vue";
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'pending-institute-target',
  components: {
    Form,
    Field,
    Datatable,
    TargetDrawer,
    ChangeSummaryDrawer,
  },
  data() {
    return {
      batch: {
        entity_id: '' as any,
        tranche_id: '' as any,
        institute_id: '',
        course_id: '',
      } as any,
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [] as any,
      course_Info: [],
      courseList: [],
      tranches_info: [],
      batchInfo: [],
      instituteList: [],
      componentKey: 0,
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '220px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Target Trainee',
          key: 'target_trainee',
          sortable: true,
        },
        {
          name: 'Batch Size',
          key: 'batch_size',
          sortable: true,
        },
        {
          name: '# of Batches',
          key: 'of_batch',
          sortable: true,
        },
        {
          name: 'Requested by',
          key: 'requested_by',
          sortable: true,
        },
        {
          name: 'Request Date Time',
          key: 'requested_date_time',
          sortable: true,
          width: '150px',
        },
      ],
      tableData: [
        {
          id: 1,
          course: 'Mobile Application Development - Android',
          institute: 'PencilBox Training Institute',
          target_trainee: '150',
          batch_size: '25 ',
          of_batch: '08',
        },
        {
          id: 2,
          course: 'Graphics & UI Design',
          institute: 'PencilBox Training Institute',
          target_trainee: '100',
          batch_size: '25 ',
          of_batch: '08',
        },
        {
          id: 3,
          course: 'PHP with Laravel Framework',
          institute: 'PencilBox Training Institute',
          target_trainee: '200',
          batch_size: '25 ',
          of_batch: '08',
        },
      ],
      data: {},
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      load: false,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      instituteIds: [] as any,
    };
  },
  async created() {
    this.emitter.on('course-updated', async () => {
      await this.courseInfo();
    });

    await this.getEntityInfos();
    await this.getTranches();
    await this.getTrainingInstitute();
  },
  methods: {
    filterEntity(entity) {
      this.getTrainingInstitute();
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.entityInfos.shift();

        this.entityInfos.forEach((val, index) => {
          if (val.id != 'select') {
            this.batch.entity_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.entityInfos = [];
        this.entityInfos.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.entityInfos = [];

        this.entityInfos = this.tempAssociation;
        this.entityInfos.unshift(this.selectObj);
        this.batch.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      this.load = true;
      await ApiService.get('entity/list')
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data.data;
          this.tempAssociation = this.entityInfos;

          this.entityInfos.forEach((val, index) => {
            this.tempId.push(val.id);
          });
          this.entityInfos.unshift(this.selectObj);
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          console.log(response);
          this.tranches_info = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.instituteIds = this.batch.entity_id;
      if (this.batch.entity_id.includes('select')) {
        this.instituteIds = this.tempId;
      }
      if (this.batch.entity_id.includes('unselect')) {
        this.instituteIds = -1;
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API + '?entity_id=' + this.instituteIds
      )
        .then((response) => {
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseInfo() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.associationData;
      }
      if (this.batch.tranche_id.length > 0 && this.batch.entity_id.length > 0) {
        await ApiService.get(
          this.VUE_APP_PENDING_COURSE_TARGET_LIST_API +
            '?entity_id=' +
            entity_id +
            '&tranche=' +
            this.batch.tranche_id +
            '&institute_info_id=' +
            this.batch.institute_id
        )
          .then((response) => {
            console.log(response);
            this.showCourseNotice = true;
            this.componentKey += 1;
            this.courseList = response.data.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Tranche and Training Partner',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institutetarget/approvetarget/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('course-updated', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('institutetarget/rejecttarget/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('course-updated', true);
              Swal.fire('Reject!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    view(data) {
      this.emitter.emit('institute_target_change_summary', data);
    },
    edit(data) {
      this.emitter.emit('institute_target_add', data);
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
